import React from 'react';
import { Button,  message, Input } from 'antd';
import API from '../utils/api';
import { CopyrightCircleOutlined } from '@ant-design/icons';
export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginName: '',
            loginPwd: '',
        }
    }

    render() {
        let { loginName, loginPwd } = this.state
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', flex: 1,backgroundColor: '#fafafa',justifyContent:'center',alignItems:'center',flexDirection:'column',}}>

                <div style={{width:400,backgroundColor:'#fff',height:500,display:'flex',flexDirection:'column',alignItems:'center',borderRadius:5,paddingTop:50,boxShadow: '15px 15px 7px rgba(0, 0, 0,0.1)'}}>
                    <img alt="" src={require('../image/icon_send.png')} style={{ width: 40, height: 40}} />
                    <div style={{ fontSize: 32, color: 'rgba(0,0,0,.85)', fontWeight: 'bold', marginBottom: 20, marginTop: 10 }}>SoReal发布平台</div>
                    <div style={{width:'80%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderBottomColor:'#ccc',borderBottomWidth:1,borderBottomStyle:'solid'}}>
                        <Input
                            style={{ width: '100%',height:40, border:'none',backgroundColor:'white' ,}}
                            value={loginName}
                            onChange={e => {
                                loginName = e.currentTarget.value;
                                this.setState({ loginName })
                            }}
                            placeholder="请填写用户名"
                            allowClear
                        />
                    </div>
                    <div style={{width:'80%',marginTop:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderBottomColor:'#ccc',borderBottomWidth:1,borderBottomStyle:'solid'}}>
                    <Input.Password
                        style={{ width: '100%',height:40, border:'none',backgroundColor:'white' ,}}
                        value={loginPwd}
                        onChange={e => {
                            loginPwd = e.currentTarget.value;
                            this.setState({ loginPwd })
                        }}
                        allowClear
                        placeholder="请填写密码"
                        onKeyUp={this.onKeyUp}
                    />
                    </div>
                    
                    <Button type="primary" style={{width: '80%', height:40,borderRadius: 5, marginTop:50}} onClick={this.Submit}>登录</Button>
                    <div style={{ marginTop: 50, fontSize: 12 }}>Copyright<CopyrightCircleOutlined />北京当红齐天国际文化科技发展集团有限公司</div>
                </div> 
            </div>
        )
    }
    onKeyUp = (e) => {
        debugger
        if (e.keyCode === 13) {
            this.Submit();
        }
    }

    Submit = () => {
        const { loginName, loginPwd } = this.state;
        if (!loginName) {
            message.error('请输入用户名!');
            return;
        }
        if (!loginPwd) {
            message.error('请输入密码!');
            return;
        }
        let params = {
            loginName,
            loginPwd
        }
        const hide = message.loading('请稍后 ...');
        API.eLogin(params).then(res => {
        
            sessionStorage.setItem('userRole', res.role);
            sessionStorage.setItem('loginName', res.loginName);
            message.success('登录成功!')
            this.props.history.replace('/home')
            hide();
        }).catch(e => {
            hide();
            message.error(e || '服务器出现错误')
        })

    }
}