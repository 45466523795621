import Request from './request';

//开发环境
// export const base = 'https://47.104.173.77';

//测试环境

// export const base = 'http://39.106.28.192:8070';

//生产环境
// export const base = 'https://api-p.skylimit.com';
export const base = '';
export const url = {
    /**
     * 登录
     */
    login: `${base}/soreal/management/login`,

    /**
     * 左侧列表名称
     */
    getProjectName: `${base}/soreal/management/managementList`,

    /**
     * 右侧项目列表
     */
    getProjectlist: `${base}/soreal/management/versionList`,

    /**
     * 创建项目名称
     */
    createProjectName: `${base}/soreal/management/addManagement`,

    /**
     * 创建项目名称
     */
    updateProjectName: `${base}/soreal/management/modifyManagement`,
    /// 删除项目
    removeProject:`${base}/soreal/management/removeManagement`,

    /**
     * 删除右侧列表版本
     */
    deleteRightList: `${base}/soreal/management/removeVersion`,

    /**
     * 上传新版本
     */
    getNewProjectVersion: `${base}/soreal/management/addVersion`,

    /// 修改版本
    updateProjectVersion:`${base}/soreal/management/modifyVersion`,
    /// 根据versionId 查询项目
    selectProjectVersion:`${base}/soreal/management/versionInfo`,

    /// 分组

    selectGroups: '/soreal/group/list',

    updateGroupItem: '/soreal/group/modify',
    insertGroupItem: '/soreal/group/add',
    deleteGroupItem: '/soreal/group/remove',


    /// oss

    selectOssKey:'/soreal/oss',

    deleteOss:'/soreal/oss/delete'
}
export default {
    /**
     * 登录
     */
    eLogin(params) {
        const _url = url.login;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },

    /**
     * 左侧项目名称
     */
    eGetProjectName(params) {
        const _url = url.getProjectName;
        return new Promise((reslove, reject) => {
            Request.get(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },

    /**
     * 右侧项目列表
     */
    eGetProjectlist(managementNum) {
        const _url = url.getProjectlist;
        return new Promise((reslove, reject) => {
            Request.get(`${_url}?managementNum=${managementNum}`).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },

    /**
     * 创建项目名称
     */
    eCreateProjectName(params) {
        const _url = url.createProjectName;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },

    /**
    * 创建项目名称
    */
    eUpdateProjectName(params) {
        const _url = url.updateProjectName;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    eRemoveProject(params){
        const _url = url.removeProject;
        debugger
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },

    /**
     * 删除右侧列表版本
     */
    eDeleteRightList(params) {
        const _url = url.deleteRightList;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },

    /**
     * 上传新版本
     */
    eGetNewProjectVersion(params) {
        const _url = url.getNewProjectVersion;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },

    eQueryGroups(params) {
        const _url = url.selectGroups;
        return new Promise((reslove, reject) => {
            Request.get(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    eModifyGroup(params) {
        const _url = url.updateGroupItem;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    eAddGroup(params) {
        const _url = url.insertGroupItem;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    eRemoveGroup(params) {
        const _url = url.deleteGroupItem;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    eQueryOssKey(){
        const _url = url.selectOssKey;
        return new Promise((reslove, reject) => {
            Request.get(_url).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    /// 从oss中删除文件
    eRemoveOssItem(params){
        const _url = url.deleteOss;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    eModifyProjectVersion(params){
        const _url = url.updateProjectVersion;
        return new Promise((reslove, reject) => {
            Request.post(_url, params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    },
    eQueryProjectVersion(params){
        const _url = url.selectProjectVersion;
        return new Promise((reslove, reject) => {
            Request.get(_url,params).then(res => {
                //服务器正常
                if (res.code == 200) {
                    //接口正常
                    reslove(res.data);
                } else {
                    reject(res.msg);
                }
            }).catch(e => {
                console.error(e)
                reject(e);
            })
        })
    }
}