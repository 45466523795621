import React from 'react';
import { Form, Modal, Input, message } from 'antd';

import '../assets/global.css';
import AliyunOSSUpload from './aliOssUpload';
import api from '../utils/api';

const { TextArea } = Input;

export const EnumProjectVersionStatus = {
    Add: '0',
    Update: '1'
}

export default class ProjectVersionManageModal extends React.Component {
    formRef = React.createRef();

    render() {
        const { managementNum } = this.props;
        return (
            <Modal width={600} title="上传新版本" visible={true} onCancel={this.onCancelClick} cancelText='取消' okText="确定" onOk={this.onOkClick}>
                <Form ref={this.formRef} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>

                    <Form.Item required label="版本号" name='versionNum' rules={[{
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback('版本号不能为空')
                            } else {
                                var reg = /^\d+\.\d+\.\d+$/;
                                if (reg.test(value)) {
                                    callback()
                                } else {
                                    callback('版本号输入不正确')
                                }

                            }
                        }
                    }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item required label="版本code" name='versionCode' rules={[
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    callback('版本code不能为空')
                                } else {
                                    var reg = /^[0-9]*$/;
                                    if (reg.test(value)) {
                                        callback()
                                    } else {
                                        callback('版本code输入不正确')
                                    }

                                }
                            }
                        }
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="md5" initialValue={this.getGuid()} name='versionMd5' rules={[{ required: true, message: 'md5不能为空' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item required label="URI" name='URI' rules={[{
                        validator: (rule, value, callback) => {
                            if (!value || value.length <= 0) {
                                callback('请上传新版本');
                            } else {
                                callback();
                            }
                        }
                    }]}>
                        <AliyunOSSUpload />
                    </Form.Item>
                    <Form.Item required label="最低版本号code" name='versionMinCode' rules={[
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    callback('最低版本号code不能为空')
                                } else {
                                    var reg = /^[0-9]*$/;
                                    if (reg.test(value)) {
                                        callback()
                                    } else {
                                        callback('最低版本code输入不正确')
                                    }
                                }
                            }
                        }
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="软件分类编号" initialValue={managementNum} name='managementNum' rules={[{ required: true, message: '软件分类编号不能为空' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="版本描述" name='versionDesc'>
                        <TextArea showCount maxLength={100} style={{ height: 120, width: 400 }} onChange={(e) => { this.setState({ versionDesc: e.target.value }) }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
    componentDidMount() {
        const { versionId } = this.props;
        if (versionId) {
            this.getProjectVersionInfo(versionId, (data) => {
                if (this.formRef) {
                    let { versionNum, versionCode, versionMd5, versionUrl, versionMinCode, managementNum, versionDesc, versionUrlDir } = data;
                    let URI = [{
                        host: versionUrlDir,
                        url: versionUrl,
                        name: versionUrl.slice(versionUrl.lastIndexOf("/") + 1),
                        status: 'done'
                    }];
                    let formValues = {
                        versionNum, versionCode, versionMd5, versionMinCode, managementNum, versionDesc, URI
                    }
                    this.formRef.current.setFieldsValue(formValues);
                }
            });
        }

    }

    onOkClick = () => {
        const { status, versionId, onCancel, managementNum } = this.props;
        this.formRef.current.validateFields().then(res => {
            /*
            res = 

            URI: Array(1)
            0: {uid: 'rc-upload-1642415765170-2', url: 'publish/1642415789255.json', lastModified: 1642054766579, lastModifiedDate: Thu Jan 13 2022 14:19:26 GMT+0800 (中国标准时间), name: 'output-metadata.json', …}
            length: 1
            managementNum: 28
            versionCode: "1001"
            versionDesc: "asdasdasd"
            versionMd5: "d3cbffc2-5018-4eb0-bd93-d8400cd45d0d"
            versionMinCode: "1001"
            versionNum: "1.0.1"
            */
            let versionUrlDir = res.URI[0].host;
            let versionUrl = res.URI[0].url;
            let params = {
                "versionNum": res.versionNum,
                "versionCode": res.versionCode,
                "versionMd5": res.versionMd5,
                "versionUrl": (versionUrl || [])[0] == "/" ? versionUrl : "/" + versionUrl,
                "versionMinCode": res.versionMinCode,
                "managementNum": managementNum,
                "versionDesc": res.versionDesc,
                "versionUrlDir": versionUrlDir
            }

            if (status == EnumProjectVersionStatus.Add) {

                // 新增
                this.addDataToServer(params, () => {
                    message.success("新增成功");
                    onCancel && onCancel(true)
                });
            } else {
                //修改
                params.versionId = versionId;
                this.updateDataToServer(params, () => {
                    message.success("修改成功");
                    onCancel && onCancel(true)
                });
            }
        }).catch(e => {
        })
    }

    onCancelClick = () => {
        const { onCancel } = this.props;
        onCancel && onCancel(false);
    }

    addDataToServer(params, fn) {
        api.eGetNewProjectVersion(params).then(res => {
            fn && fn()
        }).catch(e => message.error(e));
    }

    updateDataToServer(params, fn) {
        api.eModifyProjectVersion(params).then(res => {
            fn && fn()
        }).catch(e => message.error(e));
    }

    getProjectVersionInfo = (versionId, fn) => {

        api.eQueryProjectVersion({ versionId }).then(res => {
            fn && fn(res)
        }).catch(e => message.error(e));
    }

    getGuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}