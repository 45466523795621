import React from 'react';
import { Layout, Button, Table, message, Modal, Input, Popconfirm, Avatar, Typography, Space } from 'antd';
import { UploadOutlined, UserOutlined, EditOutlined, DeleteOutlined, GroupOutlined, FileAddOutlined } from '@ant-design/icons';
import API from '../utils/api';
import Moment from 'moment';
import LeftMenu from '../components/leftMenu';
import '../assets/global.css';
import api from '../utils/api';
import GroupManageModal from '../components/groupManageModal';
import ProjectManageModal, { EnumProjectStatus } from '../components/projectManageModal';
import ProjectVersionManageModal, { EnumProjectVersionStatus } from '../components/projectVersionManageModal';
const { Header, Sider, Content, Footer } = Layout;
const { Text } = Typography;

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            LeftProject: [],
            managementNumType: 0,
            managementName: "",
            userName: '',
            groupModalVisible: false,
            projectModalVisible: false,
            parentGroupId: null, //父级分类的id,
            projectModalStatus: EnumProjectStatus.Add,// 项目弹窗的状态
            projectVersionModalVisible: false,
            projectVersionModalStatus: EnumProjectVersionStatus.Add,// 项目弹窗的状态

        }
    }

    componentDidMount() {
        // this.getLeftProject();
        let userName = sessionStorage.getItem('loginName')
        if (!userName) {
            this.props.history.replace('/')
        }
        this.setState({
            userName
        })
    }

    //退出登录
    loginOut = () => {
        sessionStorage.clear();
        this.props.history.replace('/')
    }

    //获取右侧项目版本列表 
    getRightProjectlist = (managementNum, index) => {
        console.log(managementNum)
        const hide = message.loading('请稍后 ...');
        API.eGetProjectlist(managementNum).then(res => {
            this.setState({
                dataSource: res
            })
            hide();
            if (index != undefined) {
                this.setState({
                    managementNumType: managementNum
                })
            }
        }).catch(e => {
            message.error(e || '服务器出现错误')
            hide();
        })
    }


    render() {
        const { projectModalStatus, projectId, projectModalVisible, parentGroupId, dataSource, LeftProject, managementName, managementNumType, userName, groupModalVisible } = this.state;
        let powerType = sessionStorage.getItem('userRole');

        return (
            <Layout>
                <Header className='home-header'>
                    <div style={{ fontSize: 30, color: '#fff', fontWeight: 'bold' }}>SoReal 发布平台</div>
                    {
                        this.renderTop()
                    }
                </Header>
                <Layout>
                    <Sider width={240} className="home-content-left scroll">
                        <LeftMenu ref={o => this._menu = o} onMenuItemClick={this.onMenuItemClick} />
                    </Sider>
                    <Layout>
                        <Content style={{ padding: 24, margin: 0, display: 'flex', borderRadius: 10, flexDirection: 'column' }}>
                            {
                                this.renderBodyTop()
                            }
                            <div style={{ padding: 20, paddingTop: 0, flex: 1, backgroundColor: '#fff' }}>
                                <Table
                                    bordered

                                    rowKey={(record) => record.versionId}
                                    style={{ backgroundColor: '#292646' }}
                                    dataSource={dataSource}
                                    columns={this.getTableColumns()}
                                    bordered={true}
                                    rowClassName={() => 'editable-row'}
                                />
                            </div>
                            {
                                groupModalVisible ?
                                    <GroupManageModal
                                        onCannel={() => {
                                            this.setState({ groupModalVisible: false });
                                            this.reloadLeftMenus();
                                        }}
                                    /> : null
                            }
                            {
                                projectModalVisible ?
                                    <ProjectManageModal
                                        status={projectModalStatus}
                                        projectId={projectId}
                                        projectName={managementName}
                                        groupId={parentGroupId}
                                        onCannel={(isLoadMenu) => {
                                            this.setState({ projectModalVisible: false });
                                            isLoadMenu && this.reloadLeftMenus();
                                        }}
                                    /> : null
                            }

                            {
                                this.renderProjectVerisonEditor()
                            }
                        </Content>
                        <Footer style={{ textAlign: 'center', paddingTop: 0 }}>当红齐天 ©2022 Created by R & D Department</Footer>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
    renderTop() {
        const { userName, } = this.state;
        const powerType = sessionStorage.getItem('userRole');
        return (
            <div style={{ height: '100%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', float: 'right' }}>
                <Space>
                    {
                        powerType == 'false' ? null : <Button style={{ color: '#fff' }} type='text' onClick={() => { this.openGroupModal() }}>
                            <GroupOutlined />
                            分组管理</Button>
                    }
                    {
                        powerType == 'false' ? null : <Button style={{ color: '#fff' }} type='text' onClick={() => { this.openAddProjectModal() }}>
                            <FileAddOutlined />添加项目
                        </Button>
                    }
                    <Avatar size="large" style={{ marginLeft: 10, marginRight: 10 }} icon={<UserOutlined />} />
                    <div style={{ display: 'flex', flexDirection: 'column', height: 80, justifyContent: 'center' }}>
                        <span style={{ lineHeight: 'normal', fontSize: 14, color: '#fff', }}>{userName}</span>
                        <a className='loginOut' style={{ lineHeight: 'normal', fontSize: 14, color: '#FFF', }} onClick={() => { this.loginOut() }}>退出登录</a>
                    </div>
                </Space>
            </div>
        );
    }
    renderBodyTop() {
        const { managementName, managementNum, } = this.state;
        let powerType = sessionStorage.getItem('userRole');
        return (
            <div style={{ display: 'flex', height: 80, backgroundColor: '#fff', flexDirection: 'row', padding: "20px 20px", alignItems: 'center' }}>
                <div style={{ fontSize: 25, }}>{managementName}</div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                    <Space>
                        {
                            managementNum ? <Text copyable={{ text: `/soreal/management/selectVersion?serch=${managementNum}` }} strong style={{ marginRight: 40 }}>复制API</Text> : null
                        }

                        {
                            powerType != 'false' && managementNum ? <Button type="primary" shape="round" icon={<UploadOutlined />} onClick={this.openProjectVersionModal}>上传新版本</Button> : null
                        }
                        {
                            powerType != 'false' && managementNum ? <Button type='ghost' shape="round" icon={<EditOutlined />} onClick={this.openEditProjectModal}>编辑</Button> : null
                        }
                        {
                            powerType != 'false' && managementNum ? <Button type='primary' danger shape="round" icon={<DeleteOutlined />} onClick={this.onRemoveProjectItem}>删除</Button> : null
                        }
                    </Space>


                </div>
            </div>
        );
    }

    renderProjectVerisonEditor() {
        const { projectVersionModalVisible, managementNum, projectVersionModalStatus, versionId } = this.state;
        if (projectVersionModalVisible) {
            return (
                <ProjectVersionManageModal
                    status={projectVersionModalStatus}
                    versionId={versionId}
                    managementNum={managementNum}
                    onCancel={
                        (reload) => {
                            this.setState({ projectVersionModalVisible: false })
                            reload && this.getRightProjectlist(managementNum)
                        }
                    } />
            );
        }
        return null;
    }

    openProjectVersionModal = () => {
        const { managementNum } = this.state;
        if (!managementNum) {
            message.error("请选择应用");
            return;
        }
        this.setState({ projectVersionModalVisible: true, projectVersionModalStatus: EnumProjectVersionStatus.Add, editVersionId: "" });
    }

    openEditProjectVersionModal = (row) => {
        const { managementNum } = this.state;
        if (!managementNum) {
            message.error("请选择应用");
            return;
        }
        this.setState({ projectVersionModalVisible: true, versionId: row.versionId, projectVersionModalStatus: EnumProjectVersionStatus.Update });
    }
    closeProjectVersionModal = () => {
        this.setState({ projectVersionModalVisible: false })
    }

    openGroupModal = () => this.setState({ groupModalVisible: true })

    openAddProjectModal = () => {
        this.setState({ projectModalVisible: true, projectModalStatus: EnumProjectStatus.Add })
    }
    /// 编辑当前 项目信息
    openEditProjectModal = () => {
        this.setState({ projectModalVisible: true, projectModalStatus: EnumProjectStatus.Update })
    }
    /// 删除当前项目
    onRemoveProjectItem = () => {
        Modal.confirm({
            title: "提示",
            content: "确定删除当前项目?",
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                const { projectId } = this.state;
                //删除当前项目
                api.eRemoveProject({ id: projectId }).then(res => {
                    message.success('删除成功');
                    this.resetState();
                    this.reloadLeftMenus();
                }).catch(e => message.error(e));
            }
        })
    }

    /// 左侧菜单点击的回调
    onMenuItemClick = (item) => {
        this.setState({
            managementName: item.managementName,
            managementNum: item.managementNum,
            projectId: item.id,
            parentGroupId: item.parentGroupId
        }, () => this.getRightProjectlist(item.managementNum));

    }


    //打开编辑版本modal
    openEditModual = (record) => {
        this.UpLModal.showModal({ type: '编辑', record })
    }

    getTableColumns = () => {
        return [
            {
                title: '应用名称',
                dataIndex: 'managementName',
                key: 'managementName',
                width: 240,
                align: 'center',
            },
            {
                title: '应用版本',
                dataIndex: 'versionCode',
                key: 'versionCode',
                align: 'center',
                width: 120,
                render: (versionCode) => {
                    return (
                        versionCode ? versionCode : '-'
                    )
                }
            },
            {
                title: '版本号',
                width: 120,
                dataIndex: 'versionNum',
                key: 'versionNum',
                align: 'center',
                render: (versionNum) => {
                    return (
                        versionNum ? versionNum : '-'
                    )
                }
            },
            {
                title: '版本描述',
                dataIndex: 'versionDesc',
                key: 'versionDesc',
                align: 'center',

                render: (versionDesc) => {
                    return (
                        versionDesc ? versionDesc : '-'
                    )
                }
            },
            {
                title: '更新时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 180,
                align: 'center',
                render: (createTime) => {
                    return (
                        Moment(createTime).format('YYYY-MM-DD HH:mm:ss')
                    )
                }
            },
            {
                title: '操作',
                align: 'center',
                width: 220,
                render: (record) => {
                    let id = record.versionId;
                    let managementNum = record.managementNum;
                    let downloadUrl = record.versionUrl;
                    let urlDir = record.versionUrlDir;
                    let powerType = sessionStorage.getItem('userRole')
                    return (
                        <Space>
                            {
                                powerType == 'false' ? null : <Button type="link" size='small' disabled={powerType == 'false' ? true : false} onClick={() => { this.openEditProjectVersionModal(record) }}>编辑</Button>
                            }

                            {
                                powerType == 'false' ? null : <Popconfirm
                                    title="确认删除此版本吗?"
                                    onConfirm={() => { this.removeProjectList({ id, managementNum }) }}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button type="link" size='small' disabled={powerType == 'false' ? true : false} >删除</Button>
                                </Popconfirm>
                            }
                            <a title={`${urlDir}${downloadUrl}`} href={`${urlDir}${downloadUrl}`} type="link" size='small' >下载</a>
                        </Space>
                    )
                }
            },
        ]
    }

    //删除右侧项目列表
    removeProjectList = ({ id, managementNum }) => {
        const params = {
            versionId: id
        }
        const hide = message.loading('请稍后 ...');
        API.eDeleteRightList(params).then(res => {
            message.success('删除成功！')
            this.getRightProjectlist(managementNum);
            hide();
        }).catch(e => {
            message.error(e || '服务器出现错误')
            hide();
        })
    }

    /// 重置页面状态
    resetState = () => {
        this.setState({
            dataSource: [],
            managementNum: 0,
            managementName: "",
            userName: '',
            groupModalVisible: false,
            projectModalVisible: false,
            parentGroupId: null, //父级分类的id,
            projectModalStatus: EnumProjectStatus.Add,// 项目弹窗的状态
            versionId: "", //需要修改的versionId
        })
    }

    /// 重置左侧菜单
    reloadLeftMenus = () => {
        this._menu && this._menu.reload();
    }

}