import React from 'react';
import { Form, message, Modal, Input,  Select } from 'antd';
import '../assets/global.css';
import api from '../utils/api';

export const EnumProjectStatus = {
    Add: '0',
    Update: '1'
}

export default 
class ProjectManageModal extends React.Component {
    state = {
        groups: [],
        groupId: null,//父级分类id
        projectName: "",
        projectId: 0,
    }
    render() {
        const { groupId, projectName, groups, projectId, status } = this.state;
        return (
            <Modal
                cancelText="取消"
                okText={status == EnumProjectStatus.Update ? "修改" : "添加"}
                onCancel={() => this.props.onCannel(false)}
                width={500} style={{ width: '40%', height: 300 }}
                visible={true}
                title='应用管理'
                onOk={this.onSubmit}
            >
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <Form width={200} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                            <Form.Item label="应用名称">
                                <Input value={projectName} onChange={(e) => this.setState({
                                    projectName: e.target.value
                                })} style={{ marginRight: 10 }} placeholder='请输入应用名称' />
                            </Form.Item>
                            <Form.Item label="所属分类">
                                <Select onChange={this.onSelectChanged} value={groupId}>
                                    {
                                        groups.map(item => (
                                            <Select.Option value={item.groupId}>{item.groupName}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        const self = this;
        const { groupId, projectName, projectId, status } = self.props;
        self.getGroups((groups) => {
            if (status == EnumProjectStatus.Update) {
                self.setState({ groups, groupId, projectName, projectId, status });
            } else {
                self.setState({ groups, groupId, status });
            }
        })
    }

    onSubmit = () => {
        const { onCannel, projectId, status } = this.props;
        const { groupId, projectName } = this.state;
        if (!projectName) {
            message.error("应用名称不能为空")
        } else if (!groupId) {
            message.error("请选择所属分类");
        } else {
            let p = status == EnumProjectStatus.Update ? api.eUpdateProjectName({
                managementName: projectName,
                groupId,
                id: projectId
            }) : api.eCreateProjectName({
                managementName: projectName,
                groupId
            });

            p.then(s => {
                message.success(projectId ? "修改成功" : "添加成功");
                onCannel && onCannel(true);
            }).catch(e => {
                message.error(e);
            })
        }
    }
    onSelectChanged = (e) => {
        this.setState({ groupId: e });
    }
    /// 获取分组

    getGroups = (fn) => {
        api.eQueryGroups().then(list => {
            fn && fn(list);
        }).catch(e => message.error(e));
    }
}

