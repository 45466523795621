import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react';
import api from '../utils/api';

export default class extends React.Component{
    state = {
        dataSource:[]
    }
    render(){
        const {dataSource} = this.state;
        return (
            
            <Menu theme='light' onClick={this.onMenuItemClick} style={{width:240}} mode='inline'>
                {
                    dataSource.map(item=>{
                        return (
                            <SubMenu key={item.groupId} title={item.groupName}>
                            {
                                item.children && item.children.map(c=>{
                                    return (<Menu.Item key={c.groupId}><span>{c.groupName}</span></Menu.Item>);
                                }) 
                            }
                        </SubMenu>
                        );
                    })
                }
            </Menu>
        );
    }
    
    componentDidMount(){
        this.getGroups();
    }

    onMenuItemClick = ({key,keyPath})=>{
        const l1 = this.state.dataSource.find(item=>item.groupId == keyPath[keyPath.length - 1]);
        const l2 = l1.children.find(item=>item.groupId == key);
        this.props.onMenuItemClick && this.props.onMenuItemClick({
            ...l2,
            pGroupId:l1.groupId,
            pGroupName:l1.groupName
        })
    }

    reload = ()=>{
        this.getGroups();
    }

    /// 网络 

    getGroups = ()=>{
        const self = this;
        api.eGetProjectName().then(dataSource=>{
            self.setState({dataSource})
        }).catch(e=>{
            debugger
        })
    }
}