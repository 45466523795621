import React from "react";
import { Upload, Button, message } from 'antd';
import { UploadOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'
import Api from '../utils/api';
import api from "../utils/api";

export default class extends React.Component {
    state = {
        OSSData: {},
        serverFile: null,// {name:'',url:''}
    };
    render() {
        const { value } = this.props;
        const successFile = value ? value.filter(item => item.status == 'done') : [];
        if (value && value.length > 0 && successFile.length == value.length) {
            //已经上传
            return this.renderSuccess();
        } else {
            const props = {
                multiple: false,
                maxCount: 1,
                name: 'file',
                fileList: value,
                action: this.state.OSSData.host,
                onChange: this.onChange,
                onRemove: this.onRemove,
                data: this.getExtraData,
                beforeUpload: this.beforeUpload,
            };
            return (
                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>上传新版本</Button>
                </Upload>
            );
        }

    }

    renderSuccess() {
        const { value } = this.props;
        return value.map(item => {
            const title = `${this.state.OSSData.host}/${item.url}`;
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PaperClipOutlined />
                    <a title={title} target="_black" href={`${this.state.OSSData.host}/${item.url}`} style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        {item.name}
                    </a>
                    <DeleteOutlined onClick={() => this.onRemove(item)} />
                </div>
            );
        })
    }

    async componentDidMount() {
        await this.init();
    }

    init = async () => {
        try {
            const OSSData = await Api.eQueryOssKey();
            this.setState({
                OSSData,
            });
        } catch (error) {
            message.error(error);
        }
    };

    getExtraData = file => {
        const { OSSData } = this.state;

        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessid,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    };

    beforeUpload = async file => {
        const { OSSData } = this.state;
        const expire = OSSData.expire * 1000;

        if (expire < Date.now()) {
            await this.init();
        }

        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + '_' + file.name;//Date.now() + suffix;
        file.url = OSSData.dir + filename;

        return file;
    };

    onChange = ({ fileList }) => {
        const { OSSData } = this.state;
        const { onChange } = this.props;
        if (onChange) {
            let arr = fileList.map(item => ({ ...item, host: OSSData.host }));
            onChange([...arr]);
        }
    };

    onRemove = async (file) => {
        const { value, onChange } = this.props;
        const files = value.filter(v => v.url !== file.url);
        await api.eRemoveOssItem({ url: file.host + "/" + file.url });
        if (onChange) {
            onChange(files);
        }
    };
}