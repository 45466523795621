import React from 'react';
import ReactDOM from 'react-dom';
import App from './app'
import 'antd/dist/antd.css';

ReactDOM.render(
	<div style={{width:'100%',height:'100%',display:'flex',flex:1}}>
		<App />
	</div>,
	document.getElementById('root')
);

