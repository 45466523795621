import superagent from 'superagent';
import moment from 'moment';
import { addLog , LogModel } from './logHelper';

const queryString = (obj) => {
    let partten = '?'
    if(obj){
        let params = partten
        for (let key in obj) {
            if (params === '?') {
                params += `${key}=${obj[key]}`;
            } else {
                params += `&${key}=${obj[key]}`;
            }
        }
        return params;
    }else{
        return '';
    }
    
}
const StatusString = {
    99999:'请求超时',
    404:'地址不存在',
    405:'被拒绝的请求',
    500:'服务器出现错误',
}
const post = (url, params) => {
    let postlog = new LogModel();
    postlog.url = url;
    postlog.params = JSON.stringify(params);
    postlog.reqDate = moment().format("YYYY-MM-DD HH:mm:ss SSS"); 
    return new Promise((reslove, reject) => {
        superagent.post(url)
            .timeout({
                response: 15000,  // Wait 5 seconds for the server to start sending,
                deadline: 60000, // but allow 1 minute for the file to finish loading.
            })
            .send(params)
            .then(res => {
                let { ok,status,body } = res;
                postlog.ok = ok;
                postlog.status = status;
                postlog.body = JSON.stringify(body);
                if (ok) {
                    //服务器没问题
                    if(body.code !== 200){
                        reject(body.msg)
                    }else{
                        reslove(body);
                    }
                    
                } else {
                    //服务器出事了
                    let StatusStr = StatusString[status];
                    reject(StatusStr ? StatusStr:`未知的错误 ${status}`);
                }
                addLog(postlog);
            }).catch(e => {
                postlog.ok = false;
                postlog.status = null;
                if (e.timeout || e.code === 'ABORTED'){
                    postlog.errMsg =StatusString[99999];
                    reject(StatusString[99999]);
                }else{
                    let StatusStr = StatusString[e.status];
                    postlog.errMsg = StatusStr ? StatusStr:`未知的错误 ${e.status}`
                    reject(StatusStr ? StatusStr:`未知的错误 ${e.status}`);
                }
                addLog(postlog)
            })
    })
    
}
const get = (url, params) => {
    let postlog = new LogModel();
    postlog.url = url;
    postlog.params = params;
    postlog.reqDate = moment().format("YYYY-MM-DD HH:mm:ss SSS"); 
    const _params = queryString(params);
    return new Promise((reslove, reject) => {
        superagent.get(url + _params)
            .timeout({
                response: 15000,  // Wait 5 seconds for the server to start sending,
                deadline: 60000, // but allow 1 minute for the file to finish loading.
            })
            .then(res => {
                let { ok,status,body } = res;
                postlog.ok = ok;
                postlog.status = status;
                postlog.body = JSON.stringify(body);
                if (res.ok) {
                    //服务器没问题
                    if(body.code != 200){
                        reject(body.msg)
                    }else{
                        reslove(body);
                    }
                } else {
                    //服务器出事了
                    let StatusStr = StatusString[status];
                    reject(StatusStr ? StatusStr:`未知的错误 ${status}`);
                }
                addLog(postlog)
            }).catch(e => {
                postlog.ok = false;
                postlog.status = null;
                if (e.timeout || e.code == 'ABORTED'){
                    postlog.errMsg =StatusString[99999];
                    reject(StatusString[99999]);
                }else{
                    let StatusStr = StatusString[e.status];
                    postlog.errMsg = StatusStr ? StatusStr:`未知的错误 ${e.status}`
                    reject(StatusStr ? StatusStr:`未知的错误 ${e.status}`);
                }
                addLog(postlog)
            })
    });
}

const postForm = (url, params) => {
    let postlog = new LogModel();
    postlog.url = url;
    postlog.params = JSON.stringify(params);
    postlog.reqDate = moment().format("YYYY-MM-DD HH:mm:ss SSS");
    return new Promise((reslove, reject) => {
        superagent.post(url)
            .timeout({
                response: 5000,  // Wait 5 seconds for the server to start sending,
                deadline: 60000, // but allow 1 minute for the file to finish loading.
            })
            .type('form')
            .send(params)
            .then(res => {
                let { ok,status,body } = res;
                postlog.ok = ok;
                postlog.status = status;
                postlog.body = JSON.stringify(body);
                if (ok) {
                    //服务器没问题
                    reslove(body);
                } else {
                    //服务器出事了
                    let StatusStr = StatusString[status];
                    reject(StatusStr ? StatusStr:`未知的错误 ${status}`);
                }
                addLog(postlog);
            }).catch(e => {
                postlog.ok = false;
                postlog.status = null;
                if (e.timeout || e.code == 'ABORTED'){
                    postlog.errMsg =StatusString[99999];
                    reject(StatusString[99999]);
                }else{
                    let StatusStr = StatusString[e.status];
                    postlog.errMsg = StatusStr ? StatusStr:`未知的错误 ${e.status}`
                    reject(StatusStr ? StatusStr:`未知的错误 ${e.status}`);
                }
                addLog(postlog)
            })
    })
    
}

export default {post,get,postForm}