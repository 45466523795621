/**
 * 日志记录 app 启动时 有效
 */
export let logs = [];
export class LogModel {
    url = ''
    params = ''
    status = ''
    body = ''
    errMsg = ''
    reqDate = ''
    ok = false
}
export let addLog = (log)=>{ logs.push(log); };