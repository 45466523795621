import React from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from './home/index';
import Login from './login/index';

class App extends React.Component {
    render() {
        return (
            <Router>
                
                    <Switch>
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/" component={Login} />
                    </Switch>
                
            </Router>
        )
    }
}
export default App;
// ReactDOM.render(<App/>, document.getElementById('root'));
