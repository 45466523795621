import React from 'react';
import { Button, Table,  message, Modal, Input, Popconfirm,  Space, } from 'antd';
import '../assets/global.css';
import api from '../utils/api';


/// 分组管理的Modal弹窗
export default class GroupManageModal extends React.Component {
    state = {
        list: [],
        newGroupName: '',
    }
    render() {
        const { list, newGroupName } = this.state;
        return (
            <Modal
                footer={null}
                onCancel={this.props.onCannel}
                width={800}
                style={{ width: '80%', height: 500 }}
                visible={true}
                title='分组管理'>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                        <Input value={newGroupName} onChange={(e) => this.setState({
                            newGroupName: e.target.value
                        })} style={{ marginRight: 10 }} placeholder='需要添加的分组名称' />
                        <Button type='primary' onClick={this.onAddGroupClick}>添加</Button>
                    </div>
                    <Table dataSource={list} bordered columns={
                        [
                            {
                                title: '编号',
                                width: 100,
                                align: 'center',
                                render: (t, r, i) => (`${i + 1}`)
                            },
                            {
                                title: '分组名称',
                                dataIndex: 'groupName',
                                align: 'center',
                                render: (t, r, i) => {
                                    if (r.edited) {
                                        return <Input onPressEnter={(e) => this.onRowEditedBlur(e, i)} onBlur={(e) => this.onRowEditedBlur(e, i)} value={t} onChange={(e) => this.onRowEdited(e.target.value, i)} />
                                    }
                                    return t;
                                }
                            },
                            {
                                title: '操作',
                                align: 'center',
                                width: 200,
                                render: (text, row, index) => {
                                    return <Space>
                                        <a href='' onClick={() => this.onItemEditClick(index)}>编辑</a>
                                        <Popconfirm okText="是" onConfirm={() => this.onItemDeleteClick(row.groupId)} cancelText='否' placement="left" title="确认删除？">
                                            <a href=''>删除</a>
                                        </Popconfirm>
                                    </Space>
                                }
                            },
                        ]
                    } />
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        this.getGroups();
    }

    onAddGroupClick = () => {
        if (this.state.newGroupName) {
            api.eAddGroup({
                groupName: this.state.newGroupName,
            }).then(res => {
                this.setState({ newGroupName: '' });
                message.success("修改成功");
                this.getGroups();
            }).catch(e => message.error(e))
        } else {
            message.error("请输入分组名称")
        }
    }

    onItemEditClick = (index) => {
        let arr = [...this.state.list];
        arr[index].edited = true
        this.setState({ list: arr });
    }

    onItemDeleteClick = (groupId) => {
        api.eRemoveGroup({
            groupId,
        }).then(res => {
            this.setState({ newGroupName: '' });
            message.success("删除成功");
            this.getGroups();
        }).catch(e => message.error(e))
    }

    onRowEdited = (text, index) => {
        let arr = [...this.state.list];
        arr[index].groupName = text;
        this.setState({ list: arr });
    }

    onRowEditedBlur = (e, index) => {
        let arr = [...this.state.list];
        arr[index].edited = false;
        const newName = e.target.value;
        if (newName != arr[index].oldGroupName) {
            api.eModifyGroup({
                groupName: e.target.value,
                groupId: arr[index].groupId,
            }).then(res => {
                arr[index].oldGroupName = newName;
                this.setState({ list: arr });
                message.success("修改成功")
            }).catch(e => message.error(e))
        } else {
            this.setState({ list: arr });
        }

    }

    /// 网络
    getGroups = () => {
        api.eQueryGroups().then(list => {
            let arr = list.map(item => ({ ...item, oldGroupName: item.groupName }))
            this.setState({ list: arr })
        })
    }
}